"use client";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { RecentSearch } from "@/persistent-storage/recent-searches/types";
import { getRecentSearches } from "@/persistent-storage/recent-searches/selectors";
import { Port } from "@/global-types/ports";
import { getPortLocationName } from "@/utils/port-utils";
import { getArrivalPortData, getDate } from "./utils";
import ClockIcon from "@/icons/clock";
import { useDispatch } from "react-redux";
import { actions } from "@/store/routes";
import { Translations } from "@/global-types/translations";

interface Props {
  portsData: Port[];
  translations: Translations | undefined;
  onClose: () => void;
  lang: string;
}

const RecentSearches = ({ portsData, translations, onClose, lang }: Props) => {
  const dispatch = useDispatch();
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const storedSearches = getRecentSearches();
    if (!storedSearches) return; // Early return if no stored searches

    const filteredSearches = JSON.parse(storedSearches).filter(
      (search: RecentSearch) => {
        // Split the "dates" string by commas to handle date ranges
        const dates = search.dates.split(",");
        // Check if all dates are today or in the future
        return dates.every((date) => dayjs(date).isSameOrAfter(dayjs(), "day"));
      },
    );

    setRecentSearches(filteredSearches);
  }, []);

  const handleClick = (data: RecentSearch) => {
    dispatch(actions.changeRoutesFromCache(data));
    onClose();
  };

  return (
    <ul className="flex-1 overflow-y-auto">
      <li className="sticky top-0 bg-white px-4 py-2">
        <b>{translations?.recent_searches || "Recent Searches"}</b>
      </li>
      {recentSearches.map((search, index) => {
        const { departurePorts, arrivalPorts, dates } = search;
        const departurePortId = departurePorts.split(",")[0];
        const departurePortName = getPortLocationName(
          departurePortId,
          portsData,
          lang,
        );
        const { arrivalPortId, totalTrips } = getArrivalPortData(arrivalPorts);
        const arrivalPortName = getPortLocationName(
          arrivalPortId,
          portsData,
          lang,
        );
        const arrivalPortTerm = `${arrivalPortName} ${totalTrips}`;
        const date = getDate(dates);
        return (
          <li
            key={index}
            className="flex cursor-pointer items-start gap-4 px-4 py-2 hover:bg-blue-50"
            onClick={() => handleClick(search)}
          >
            <ClockIcon width={18} height={18} className="mt-1 shrink-0" />
            <div>
              {departurePortName} - {arrivalPortTerm}
              <span className="block text-sm text-gray-600">{date}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default RecentSearches;
