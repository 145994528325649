import { useSelector } from "react-redux";
import { selectors } from "@/store/searchMask";
import { isGreekText } from "@/utils/bilingual-utils";
import { Port } from "@/global-types/ports";

const useBilingualPorts = (portsData: Port[], lang: string) => {
  const isDeparturePortInGreek = useSelector(
    selectors.getIsDeparturePortInGreek,
  );

  const shouldUseGreek =
    (lang === "el" &&
      (isDeparturePortInGreek == null || isDeparturePortInGreek)) ||
    (lang !== "el" && isDeparturePortInGreek);

  return portsData.filter((port) =>
    shouldUseGreek
      ? isGreekText(port.locationName)
      : !isGreekText(port.locationName),
  );
};

export default useBilingualPorts;
