import { Fragment } from "react";
import { Port } from "@/global-types/ports";
import ListItem from "../list-item";

import {
  collectPortsInNestedLists,
  createPortsMap,
  filterSuggestions,
} from "./utils";
import { Translations } from "@/global-types/translations";
import { isGreekText } from "@/utils/bilingual-utils";

interface Props {
  portsData: Port[];
  searchTerm: string;
  routeType: "departure" | "arrival";
  routeIndex: number;
  translations: Translations | undefined;
  onSuggestionClick: (suggestion: string) => void;
}

const FilteredPorts = ({
  portsData,
  searchTerm,
  routeType,
  routeIndex,
  translations,
  onSuggestionClick,
}: Props) => {
  const portsDataBasedOnLanguage = portsData.filter(
    (port) =>
      (isGreekText(searchTerm) && isGreekText(port.locationName)) ||
      (!isGreekText(searchTerm) && !isGreekText(port.locationName)),
  );

  const filteredSuggestions = filterSuggestions(
    searchTerm,
    portsDataBasedOnLanguage,
  );
  const portsInNestedLists = collectPortsInNestedLists(
    portsDataBasedOnLanguage,
  );
  const portsMap = createPortsMap(portsDataBasedOnLanguage);

  if (filteredSuggestions.length === 0) {
    return (
      <ul>
        <li className="p-4">
          {translations?.no_results_found || "No results found"}
        </li>
      </ul>
    );
  }

  return (
    <ul className="overflow-y-auto py-2">
      {filteredSuggestions.map((port) => {
        const shouldRenderItem = !portsInNestedLists.includes(
          port.locationName,
        );
        if (!shouldRenderItem && !port.childPorts.length) {
          return null;
        }
        return (
          <Fragment key={port.ferryOwnLocationID}>
            <ListItem
              port={port}
              routeType={routeType}
              routeIndex={routeIndex}
              onSuggestionClick={onSuggestionClick}
            />
            <ul>
              {port.childPorts.map((childPortId) => {
                const childPort = portsMap[childPortId];

                if (!childPort) return null;

                return (
                  <ListItem
                    key={childPort.ferryOwnLocationID}
                    port={childPort}
                    routeType={routeType}
                    routeIndex={routeIndex}
                    onSuggestionClick={onSuggestionClick}
                    nested
                  />
                );
              })}
            </ul>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default FilteredPorts;
