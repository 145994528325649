import { useDispatch } from "react-redux";
import { actions as routesActions } from "@/store/routes";
import { actions as searchMaskActions } from "@/store/searchMask";
import { Port } from "@/global-types/ports";
import clsx from "clsx";
import { isGreekText } from "@/utils/bilingual-utils";

interface Props {
  port: Port;
  routeType: "departure" | "arrival";
  routeIndex: number;
  nested?: boolean;
  onSuggestionClick: (suggestion: string) => void;
}

const ListItem = ({
  port,
  routeType,
  routeIndex,
  nested,
  onSuggestionClick,
}: Props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    onSuggestionClick(port.locationName);

    if (routeType === "departure") {
      dispatch(
        routesActions.changeRouteDeparture({
          index: routeIndex,
          value: port.ferryOwnLocationID,
        }),
      );
      dispatch(searchMaskActions.changeOpenReturnField(`to-${routeIndex}`));
      dispatch(
        searchMaskActions.changeIsDeparturePortInGreek(
          isGreekText(port.locationName),
        ),
      );
    } else {
      dispatch(
        routesActions.changeRouteArrival({
          index: routeIndex,
          value: port.ferryOwnLocationID,
        }),
      );
      dispatch(searchMaskActions.changeOpenDateField(routeIndex));
    }
  };

  return (
    <li
      onClick={handleClick}
      className={clsx("cursor-pointer px-4 py-2 hover:bg-blue-50", {
        "pl-8": nested,
      })}
    >
      {port.locationName}
    </li>
  );
};

export default ListItem;
